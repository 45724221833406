<template>
  <div class="home">
    <div class="banner">
      <!-- <img src="../assets/img/h_1.png" alt="" /> -->
      <div class='bannerbox'>
          <div class='b-b1 po'></div>
          <img class='b-b2 po' src='../assets/img/h_3.png'>
          <img class='b-b3 po jqr' src='../assets/img/h_4.png'>
          <img class='b-b4 po ' src='../assets/img/h_5.png'>
          <img class='b-b5 po' src='../assets/img/h_6.png'>
          <div class='b-b6 po'>
            <img class='b-yu'  src='../assets/img/h_7.png'>
          </div>
           <div class='b-b7 po'>
            <img class='b-yu' src='../assets/img/h_8.png'>
          </div>
           <img class='b-b8 po' src='../assets/img/h_9.png'>
           <div class='b-b9 po'>
            <img class='b-yu'  src='../assets/img/h_7.png' style="left:-9px">
          </div>
      </div>
      <div class="banner-text">
        <!-- <div class="banner-title">领先的工业智能化服务供应商</div> -->
        <!-- <div class="banner-subTitle">
          LEADER OF INDUSTRIAL INTELLIGENCE SERVICE
        </div> -->
      </div>
    </div>
    <div class="energy-wrap aiOPS-wrap">
        <img class='energy-bjimg fadeOutUp'
        ref="animate" :class="{ fadeInUp: animateData.animate }" src="../assets/img/BUSINESSFOCUS.png" alt="" />

      <div
        class="energy-title fadeOutUp"
        ref="animate"
        :class="{ fadeInUp: animateData.animate }"
      >
        业务聚焦
      </div>
      <div
        class="energy-subTitle fadeOutUp"
        ref="animate4"
        :class="{ fadeInUp: animateData.animate4 }"
        style="transition-delay: 0.2s;width:1118px"
      >
      <!--  -->
      聚焦数据要素市场，致力于构建标识解析、确权登记、数据交易、资产入表等数据资产全生命周期产品服务体系，以促进企业<span  style='color:#414b59;font-size:20px'>“增值、增信、增益”</span>为手段，助力企业数据资产价值释放，实现国家战略和企业发展的互利、共赢。公司成立以来，始终以推进数据要素市场化为使命，并立志成为领先的数据资产服务提供商。

      </div>
      <!-- 第二层 -->
      <div class="aiOPS">
          <img class='aiOPS_bjimg' src="../assets/img/home_2.png" alt="" />
          <div class='aiOPS-box'>
            <div class='aiOPS_block  energy-title fadeOutUp tran'
             ref="animate3"
            :class="{ fadeInUp : animateData.animate3 ,  aiOPS_block2: animateData.animate3 ,aiOPS_block3:istwo }"
            >
                  <div class='quan quan1 tran'></div>
                  <div class='quan quan2 tran'></div>
                  <img class='a-top po tran' src="../assets/img/home_15.png" alt=""  />
                  <img class='a-leftt po tran' src="../assets/img/home_16.png" alt="" />
                  <img class='a-rigtt po tran' src="../assets/img/home_16_1.png" alt="" />
                  <!-- <img class='a-rigy po tran' src="../assets/img/home_18.png" alt="" /> -->
                  <!-- <img class='a-lefty po tran' src="../assets/img/home_19.png" alt="" /> -->
                  <img class='a-leftj po tran' src="../assets/img/home_20.png" alt="" />
                  <img class='a-rigj po tran' src="../assets/img/home_21.png" alt="" />
                  <div class='a-cen po tran'>
                      <!-- <img  src="../assets/img/home_17.png" alt="" /> -->
                      <p>数据要素市场</p>
                  </div>
                  <div class='a-nav po a-tl  tran'>
                      <div class='a-navbox'>
                        <img class='a-imgt po tran '  :class='{ opt:istree,xhdh:!istree}' src="../assets/img/home_23.png" alt="" />
                        <img class='a-imgb ' src="../assets/img/home_22.png" alt="" />
                      </div>
                  </div>
                   <div class='a-nav po a-tr  tran'>
                     <div class='a-navbox'>
                        <img class='a-imgt po tran' :class='{ opt:istree,xhdh:!istree}' src="../assets/img/home_25.png" alt="" />
                        <img class='a-imgb ' src="../assets/img/home_24.png" alt="" />
                      </div>
                  </div>

                   <div class='a-nav po a-bl  tran'>
                     <div class='a-navbox'>
                        <img class='a-imgt  po tran' :class='{ opt:istree,xhdh:!istree}' src="../assets/img/home_29.png" alt="" />
                        <img class='a-imgb ' src="../assets/img/home_28.png" alt="" />
                      </div>
                  </div>
                  <div class='a-nav po a-br  tran'>
                     <div class='a-navbox'>
                        <img class='a-imgt  po tran' :class='{ opt:istree,xhdh:!istree}' src="../assets/img/home_27.png" alt="" style="top:15px"/>
                        <img class='a-imgb ' src="../assets/img/home_26.png" alt="" />
                      </div>
                  </div>
            </div>
          </div>

      </div>
    </div>
    <!-- 三层 -->
    <div class="energy-wrap aiService-wrap">
      <div
        class="energy-title fadeOutUp energy-h1"
        ref="animate1"
        :class="{ fadeInUp: animateData.animate1 }"
      >
        <img class='aiOPS_bjimg' src="../assets/img/COREPRODUCTS.png" alt="" />
      </div>
      <div
        class="energy-subTitle fadeOutUp energy-h2 h46"
        ref="animate5"
        :class="{ fadeInUp: animateData.animate5 }"
        style="transition-delay: 0.2s"
      >
        核心产品
      </div>
      <div class='core-box  l-between'
      >
          <div
          v-for='(itm,index) in coreList'  :key='index' @mouseenter='coreFn(index,itm)' @click='goFn(itm)'
          class='fadeOutUp'
          :class='["core-block "+"core-block"+itm.id +((index==coreidx)?" core-active xiaos":""),{ fadeInUp: animateData[itm.animate] }]'
          :ref="itm.animate"
          :style="{
            transitionDelay: itm.delay + 's',
          }"

          >
             <h3>{{itm.name}}</h3>
             <div class='core-cont'>{{itm.cont}}</div>
             <!-- <img class='img tran' :src='itm.img'> -->
             <div class='core-w tran l-between' :class="{'l-center':index===1}">
                <div class='core-div' :class='"core-div"+itm.id' v-for='item in itm.list' :key='item.title' v-if="itm.list.length">
                    <img :src='item.img' >
                    <h3 class='core-h3'>{{item.title}}</h3>
                    <div class='core-children' v-for='item2 in item.children' :key='item2.title'>{{item2.title}}</div>
                </div>
                <img :src='itm.img2' v-if="!itm.list.length" :class='"core-img"+itm.id'>
             </div>
             <div class='l-start core-bom'>
               <span>{{itm.title}}</span>
               <img :src='itm.src'>
             </div>
          </div>
      </div>


    </div>
    <div class="energy-wrap">
      <div
        class="energy-title fadeOutUp energy-h1"
        ref="animate2"
        :class="{ fadeInUp: animateData.animate2 }"
      >
         <img class='aiOPS_bjimg' src="../assets/img/COOPERATIVEPARTNER.png" alt="" />
      </div>
      <div
        class="energy-subTitle fadeOutUp energy-h2 partner-h2"
        ref="animate6"
        :class="{ fadeInUp: animateData.animate6 }"
        style="transition-delay: 0.2s"
      >
        合作伙伴
      </div>
      <div class="energy">
        <div
          class="partner-box  l-between l-wrap"

        >
          <div class='partner-block fadeOutUp'  v-for="item in partList" :key="item.name"
          :class='{ fadeInUp: animateData[item.animate] }'
          :ref="item.animate"
          :style="{
            transitionDelay: item.delay + 's',
          }"
          >
            <div class='partner-imgbox'>
              <img class='partner-img' :src="item.src" alt="" />

            </div>
          </div>

        </div>
      </div>
    </div>
    <!-- <div class="partner-wrap">
      <div
        class="partner-head fadeOutUp"
        ref="animate3"
        :class="{ fadeInUp: animateData.animate3 }"
      >
        合作伙伴
      </div>
      <div class="partner">
        <img
          v-show="partnerVisible"
          class="rubberBand animated"
          v-for="(item, index) in partnerList"
          :key="index"
          :src="item.src"
          :style="{
            left: item.left + 'px',
            top: item.top + 'px',
            animationDelay: item.delay + 's',
          }"
          alt=""
        />
      </div>
    </div> -->
  </div>
</template>

<script>
// @ is an alias to /src
import { isElementNotInViewport } from "../utils/index.js";

export default {
  name: "Home",
  data() {
    return {
      partnerVisible: false,
      istwo:true,
      istree:true,
      coreidx:0,
      coreList:[
        {title:"专业系统 ",src:require("../assets/img/home_3.png"),name:"蜂筑工业互联网平台",cont:"数据交易系统+应用服务系统+行业分析系统+运维监管系统+标识解析系统 ",
          img:require("../assets/img/home_30.png"),
          img2:require("../assets/img/home_51.png"),
          animate: "animate20",
          delay: 0.1,url:"/productN/productIndex",
          id:0,
          list:[]
        },
        {title:"数据资产",src:require("../assets/img/home_56.png"),name:"数据资产确权登记平台",cont:"标识解析+确权登记+质量评价+价值评估+数据交易+资产入表",
           img:require("../assets/img/home_32.png"),
          animate: "animate22",
          delay: 0.2,url:"/productN/productRobot",
          img2:require("../assets/img/home_53.png"),
          id:3,
          list:[ ]
        },
        {title:"可信交易",src:require("../assets/img/home_57.png"),name:"数据产品可信交易平台",cont:"交易平台+数据空间+本地应用",
           img:require("../assets/img/home_32.png"),
          animate: "animate22",
          delay: 0.3,url:"/productN/productRobot",
          img2:require("../assets/img/home_55.png"),
          id:4,
          list:[ ]
        },
        {title:"自动测试",src:require("../assets/img/home_5.png"),name:"TMS自动化测试系统",cont:"接口测试+ 界面测试+移动端测试+性能测试+安全测试+业务巡测",
           img:require("../assets/img/home_32.png"),
          animate: "animate22",
          delay: 0.1,url:"/productN/productRobot",
          img2:require("../assets/img/home_52.png"),
          id:1,
          list:[ ]
        },
        {title:"客户管理 ",src:require("../assets/img/home_4.png"),name:"CRM客户关系管理系统",cont:"获客管理+营销管理+交易管理+客服中心",
          img:require("../assets/img/home_31.png"),
          animate: "animate21",
          delay: 0.2,url:"/productN/productHSE",
          id:2,
          list:[
            {
              img:require("../assets/img/home_37.png"),
              title:"获客管理",
              children:[
                {title:"线索管理"},{title:"客户管理"},{title:"拜访管理"},
              ]
            },
            {
              img:require("../assets/img/home_38.png"),
              title:"营销管理",
              children:[
                {title:"商机管理"},{title:"渠道管理"},{title:"营销活动"},
              ]
            },
            {
              img:require("../assets/img/home_39.png"),
              title:"交易管理",
              children:[
                {title:"合同管理"},{title:"回款管理"},{title:"对账分润"},
              ]
            },
            {
              img:require("../assets/img/home_40.png"),
              title:"客服中心",
              children:[
                {title:"来电工作台"},{title:"在线客服"},{title:"知识库 "},
              ]
            },

          ]
        },
        // {title:"设备管理",src:require("../assets/img/home_6.png"),name:"EMS设备运维管理系统",cont:"设备管理+维修保养+点巡检+实时监测+备品备件",
        //   img:require("../assets/img/home_33.png"),
        //   animate: "animate23",
        //   delay: 0.4,url:"/productN/productVibration",
        //   list:[
        //     {
        //       img:require("../assets/img/home_41.png"),
        //       title:"设备管理",
        //       children:[
        //         {title:"设备台账"},{title:"结构树"},{title:"设备盘点"},{title:"设备处置"}
        //       ]
        //     },
        //     {
        //       img:require("../assets/img/home_42.png"),
        //       title:"维修保养",
        //       children:[
        //         {title:"维修管理"},{title:"保养管理"},{title:"工单管理"},{title:"维保人员"}
        //       ]
        //     },
        //     {
        //       img:require("../assets/img/home_43.png"),
        //       title:"点巡检",
        //       children:[
        //         {title:"检查项目"},{title:"点检标准"},{title:"巡检计划"},{title:"巡检任务"}
        //       ]
        //     },
        //     {
        //       img:require("../assets/img/home_44.png"),
        //       title:"实时监测",
        //       children:[
        //         {title:"设备状况"},{title:"故障分析"},{title:"工作总览 "},{title:"保障日历"}
        //       ]
        //     },
        //     {
        //       img:require("../assets/img/home_45.png"),
        //       title:"备品备件",
        //       children:[
        //         {title:"采购管理"},{title:"库存台账"},{title:"出入库 "},{title:"领退申请"}
        //       ]
        //     },

        //   ]

        // },


      ],
      partList:[

      ],
      partnerList: [
        {
          src: require("../assets/images/partner_1.png"),
          left: 150,
          top: 210,
          delay: 1,
        },
        {
          src: require("../assets/images/partner_2.png"),
          left: 255,
          top: 75,
          delay: 1.3,
        },
        // {
        //   src: require("../assets/images/partner_3.png"),
        //   left: 120,
        //   top: 405,
        //   delay: 0.5,
        // },
        {
          src: require("../assets/images/partner_3.png"),
          left: 435,
          top: 190,
          delay: 1.6,
        },
        {
          src: require("../assets/images/partner_4.png"),
          left: 419,
          top: -41,
          delay: 0.8,
        },
        {
          src: require("../assets/images/partner_5.png"),
          left: 300,
          top: 365,
          delay: 0.2,
        },
        {
          src: require("../assets/images/partner_6.png"),
          left: 555,
          top: 315,
          delay: 1.2,
        },
        {
          src: require("../assets/images/partner_7.png"),
          left: 572,
          top: 66,
          delay: 0.6,
        },
        {
          src: require("../assets/images/partner_8.png"),
          left: 680,
          top: 220,
          delay: 1.6,
        },
        // {
        //   src: require("../assets/images/partner_9.png"),
        //   left: 435,
        //   top: 190,
        //   delay: 1.6,
        // },
        {
          src: require("../assets/images/partner_10.png"),
          left: 750,
          top: 390,
          delay: 1.6,
        },
      ],
      energyList: [
        {
          src: require("../assets/images/energy_1.png"),
          name: "节能",
          width: 112,
          delay: 0.1,
          animate: "animate7",
          content:
            "通过对中央空调系统各个节点加装传感器来收集其运行数据，并凭借此类数据不断优化算法模型，进而实现对系统进行动态控制和调整，使整个系统达到最佳运行状态，从而实现20%-40%的节能效果",
        },
        {
          src: require("../assets/images/energy_2.png"),
          name: "能源大数据中心",
          width: 92,
          delay: 0.2,
          animate: "animate8",
          content:
            "建立大数据业务平台、公共云服务平台、超算平台，并通过接入多维度的电力数据，建立业务模型和采取复杂的计算服务，来为电网企业、政府、发电企业和制造业提供综合服务",
        },
        // {
        //   src: require("../assets/images/energy_5.png"),
        //   name: "动态增容",
        //   width: 106,
        //   delay: 0.25,
        //   animate: "animate11",
        //   content:
        //     "通过搭载智能监测装置，并构建输电线路容量联动系统，实现运行方式的智能计算的同时，再配套限额设定、容量预测、实时评估等专项功能，达到输电线路容量联动控制的应用目的",
        // },
        {
          src: require("../assets/images/energy_5.png"),
          name: "储能",
          width: 106,
          delay: 0.25,
          animate: "animate11",
          content:
            "通过建立单独成舱的储能系统，并配置相应的消防系统、温控系统、风道均压系统，来提供定制化储能系统方案，增加能源利用率、改善电能质量、提升传统电力系统灵活性、经济性和安全性",
        },
        {
          src: require("../assets/images/energy_4.png"),
          name: "弹性电网",
          width: 105,
          delay: 0.15,
          animate: "animate10",
          content:
            "通过建设多元融合高弹性电网、应用“大云物移智链”等技术手段，实现电网赋能，释放电网潜力，丰富电网调剂手段，提高电网安全水平的同时大幅提升电网运行效率",
        },
        {
          src: require("../assets/images/energy_3.png"),
          name: "多站融合",
          width: 105,
          delay: 0.3,
          animate: "animate9",
          content:
            "通过规划设计并分步实现“十站融合”方案，以此推进微电网、智慧能源、数字能源深度开发应用，构建投资界面清晰、运营有序高效、环境开放友好的运营模式",
        },
        {
          src: require("../assets/images/energy_6.png"),
          name: "煤改电",
          width: 123,
          delay: 0.35,
          animate: "animate12",
          content:
            "通过建设基于清洁能源的超传导温控自动化节能供暖系统，在实现新旧动能转化的同时，还能大幅节省热水供应量，并大幅降低运行费用，切实地将科技成果转化为现实生产力，并创造更高的经济价值",
        },
      ],
      aiServiceIndex: 1,

      timer: null,
      animateData: {
        animate: false,
        animate1: false,
        animate2: false,
        animate3: false,
        animate4: false,
        animate5: false,
        animate6: false,
        animate20: false,
        animate21: false,
        animate22: false,
        animate23: false,
      },
      visibile: true,
      iconPosition: [
        { left: 300, top: -20, width: 70, height: 70, imgWidth: 33 },
        { left: 660, top: -20, width: 70, height: 70, imgWidth: 34 },
        { left: 960, top: 160, width: 82, height: 82, imgWidth: 42 },
        { left: 660, top: 330, width: 110, height: 110, imgWidth: 52 },
        { left: 310, top: 330, width: 110, height: 110, imgWidth: 56 },
        { left: 10, top: 160, width: 82, height: 82, imgWidth: 41 },
      ],
      picPosition: [
        //pic
        { left: 300, top: -115, width: 140, lineHeight: 21 },
        { left: 660, top: -110, width: 140, lineHeight: 21 },
        { left: 960, top: 330, width: 210, lineHeight: 31 },
        { left: 660, top: 520, width: 255, lineHeight: 37 },
        { left: 310, top: 520, width: 253, lineHeight: 37 },
        { left: 10, top: 335, width: 208, lineHeight: 31 },
      ],

      iconNamePosition: [
        { padding: "5px 7px", fontSize: 12, left: 300, top: 55 },
        { padding: "5px 8px", fontSize: 12, left: 660, top: 55 },
        { padding: "7px 20px", fontSize: 14, left: 960, top: 255 },
        { padding: "7px 19px", fontSize: 14, left: 660, top: 455 },
        { padding: "7px 19px", fontSize: 14, left: 310, top: 455 },
        { padding: "7px 9px", fontSize: 14, left: 10, top: 255 },
      ],

      radioNamePosition: [
        {
          left: 300,
          top: 130,
          fontSize: 12,
          padding: "5px 10px",
        },
        {
          left: 660,
          top: 125,
          fontSize: 12,
          padding: "5px 10px",
        },
        {
          left: 775,
          top: 210,
          fontSize: 14,
          padding: "6px 15px",
        },
        {
          left: 660,
          top: 290,
          fontSize: 14,
          padding: "6px 15px",
        },
        {
          left: 310,
          top: 290,
          fontSize: 14,
          padding: "6px 8px",
        },
        {
          left: 190,
          top: 200,
          fontSize: 12,
          padding: "5px",
        },
      ],
    };
  },
  methods: {
    goFn(itm){
      document.title = itm.name;
      this.$router.push({
        path: itm.url,
      });
    },
    imgFn(){
      this.partList=[]
      let delay=0
      for(let i=0;i<15;i++){
        let idx= i+1
        let adn ="animate"+(30+idx)
        delay +=0.07

        let u ={src: require("../assets/img/b"+ idx +".png"),animate:adn,delay:delay}
        this.partList.push(u)
      }
      // let u = "../assets/img/b"+ i +".png"//require("../assets/img/b"+ i +".png")
      // return u

    },
    coreFn(index,itm){
      this.coreidx = index
    },
    productHandle(path) {
      this.$router.push({
        path,
      });
    },

    // 滚动事件
    scrollToTop() {

      for (let i in this.$refs) {
        !isElementNotInViewport(this.$refs[i])
          ? (this.animateData[i] = true)
          : (this.animateData[i] = false);
          if(i=='animate3' && !isElementNotInViewport(this.$refs[i])){
              setTimeout(()=>{
                this.istwo = false

              },1000)
               setTimeout(()=>{
                  this.istree = false
                },1500)
          }
          if(i=='animate3' && isElementNotInViewport(this.$refs[i])){
                this.istwo = true
                this.istree = true
          }
      }
    },
    scrollToPartner() {
      if (
        window.pageYOffset >=
        document.querySelector(".partner-wrap").offsetTop - 200
      ) {
        this.partnerVisible = true;
      } else {
        this.partnerVisible = false;
      }
    },
  },
  mounted() {
    let that = this;
    this.imgFn()
    // if (window.pageYOffset>= document.querySelector(".partner-wrap").offsetTop) {
    //   this.partnerVisible = true;
    // }
    // window.addEventListener("scroll", this.scrollToPartner);
    // this.switchInit();
    // document.querySelector(".aiOPS").onmouseover = function () {
    //   clearInterval(that.timer);
    // };
    // document.querySelector(".aiOPS").onmouseout = function () {
    //   that.switchInit();
    // };
    this.scrollToTop();
    // 监听滚动事件
    window.addEventListener("scroll", this.scrollToTop);
  },
  beforeDestroy() {
    clearInterval(this.timer);
    window.removeEventListener("scroll", this.scrollToTop);
    window.removeEventListener("scroll", this.scrollToPartner);
  },
};
</script>
<style lang="less" scoped>
.l-center{
  justify-content: center;
  align-items: center;
}
.home {
  background: #f8fbff;
}
//banner
.banner {
  width: 100%;
  position: relative;
  height:832px;
  min-height: 832px;
  background: url("../assets/img/h_1.png") no-repeat;
  background-size: 100% 100%;
  .bannerbox{
    width:1350px;
    height:586px;
    margin:0 auto;
    position: relative;
    top:132px;
    .b-b1{
      width:1675px;
      height:472px;
      background: url("../assets/img/h_2.png") no-repeat;
      background-size: 100% 100%;
      z-index:1;
      top: 54px;
      left: -85px;
    }
    .b-b2{
      z-index:10;
      top:190px;
      right:220px;
    }
    .b-b3{// 机器人
      top:160px;
      left:268px;
      z-index:5;
    }
    .b-b4{
      top:33px;
      left:221px;
      z-index:8;
    }
    .b-b5{
      z-index:10;
      top:46px;
      left:0;
      z-index: 2;
    }
    .b-b6,.b-b9{
      width:43px;
      height:155px;
      overflow: hidden;
      top:38px;
      left:516px;
      z-index: 5;
      img{
        position: absolute;
        left:0;
        top:0;
      }
    }
    .b-b9{
      top:199px;
      left:19px;
      height: 141px;
    }
    .b-b7{
      width:126px;
      height:277px;
      overflow: hidden;
      top: -60px;
      left:269px;
      z-index: 3;
      img{
        position: absolute;
        left:0;
        top:0;
      }
    }
    .b-b8{
      width: 157px;
      height: 277px;
      overflow: hidden;
      top: -60px;
      left: 243px;
      z-index: 4;
    }
  }

 .po{
   position: absolute;
 }
 .jqr{
      animation: myfirst2 1.5s 1s linear infinite alternate;
    -webkit-animation: myfirst2 1.5s 1s linear infinite alternate; /* Safari 与 Chrome */
  }
  .b-yu{
    animation: myfirst3 2s 1s linear infinite normal;
    -webkit-animation: myfirst3 2s 1s linear infinite normal; /* Safari 与 Chrome */
  }

    @keyframes myfirst2
        {
            from {top: 140px;}
            to {top: 168px;}
        }



    @keyframes myfirst3
        {
            0% {top: -100%;opacity: 1;}
            50% {top: -50%;opacity: 1;}
            100% {top: 0%;opacity: 1;}
        }
}
//合作伙伴
.partner-wrap {
  min-height: 200px;
  background: url("../assets/images/partner-bg.png") no-repeat;
  background-size: 100% 100%;
  padding-top: 60px;
  .partner-head {
    font-size: 40px;
    color: #fff;
    text-align: center;
    line-height: 52px;
    height: 52px;
    margin-bottom: 100px;
  }
  .partner {
    background: url("../assets/images/partner-earth.png") no-repeat;
    background-size: 100% 100%;
    width: 955px;
    height: 610px;
    margin: 0 auto;
    position: relative;
    img {
      position: absolute;
      transition: all .8s ease-in-out;
      &:hover{
        transform: scale(1.1) !important;
      }
    }
  }
}
@keyframes rubberBand {
  0% {
    -webkit-transform: scale(0);
    -moz-transform: scale(0);
    transform: scale(0);
  }

  // 40% {
  //   -webkit-transform: scale3d(1.2, 1.2, 1);
  //   -moz-transform: scale3d(1.2, 1.2, 1);
  //   transform: scale3d(1.2, 1.2, 1);
  // }

  // 50% {
  //   -webkit-transform: scale3d(0.85, 0.85, 1);
  //   -moz-transform: scale3d(0.85, 0.85, 1);
  //   transform: scale3d(0.85, 0.85, 1);
  // }

  // 60% {
  //   -webkit-transform: scale3d(1.15, 1.15, 1);
  //   -moz-transform: scale3d(1.15, 1.15, 1);
  //   transform: scale3d(1.15, 1.15, 1);
  // }

  // 70% {
  //   -webkit-transform: scale3d(0.9, 0.9, 1);
  //   -moz-transform: scale3d(0.9, 0.9, 1);
  //   transform: scale3d(0.9, 0.9, 1);
  // }
  // 80% {
  //   -webkit-transform: scale3d(1.05, 1.05, 1);
  //   -moz-transform: scale3d(1.05, 1.05, 1);
  //   transform: scale3d(1.05, 1.05, 1);
  // }

  // 95% {
  //   -webkit-transform: scale3d(0.95, 0.95, 1);
  //   -moz-transform: scale3d(0.95, 0.95, 1);
  //   transform: scale3d(0.95, 0.95, 1);
  // }
  to {
    -webkit-transform: scaleX(1);
    -moz-transform: scaleX(1);
    // transform: scaleX(1);
    transform: scale(1);
  }
}

.rubberBand {
  -webkit-animation-name: rubberBand;
  -moz-animation-name: rubberBand;
  animation-name: rubberBand;
  -webkit-transform-origin: bottom center;
  -moz-transform-origin: bottom center;
  transform-origin: bottom center;
}
.animated {
  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
  animation-duration: 0.5s;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  animation-fill-mode: both;
}
//综合能源服务
.energy-wrap {
  background: #fff;
  padding-top: 90px;
  padding-bottom: 15px;
   .energy-bjimg{
     display: block;
      z-index: -1;
      margin:0 auto;
    }
  .energy-title {
    height: 50px;
    font-size: 28px;
    color: #414B59;
    line-height: 37px;
    text-align: center;
    position: relative;
    top:-20px;
  }
  .energy-subTitle {
    // font-size: 18px;
    // color: #818cad;
    // line-height: 36px;
    // text-align: center;
    // position: relative;
    // left: -5px;

    width: 1139px;
    height: 96px;
    font-size: 16px;
    font-family: MicrosoftYaHei;
    color: #909399;
    line-height: 32px;
    margin:0 auto;
    text-align: center;
  }

  .energy {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 1180px;
    margin: 0 auto ;
    position: relative;
    top:-50px;
  }
  .energy-item {
    width: 224px;
    height: 95.5px;
    box-sizing: border-box;
    padding: 39px 0 0;
    position: relative;
    bottom: 0;
    transition: all 0.3s ease-in-out;
    &:hover {
      box-shadow: 0px 0px 10px 8px rgba(90, 90, 90, 0.08);
      border-radius: 8px;
      bottom: 6px;
    }
    .icon {
      width: 124px;
      height: 117px;
      margin: 0 auto 15px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    span {
      font-size: 18px;
      color: #243159;
      display: block;
      text-align: center;
    }
    .content {
      margin-top: 15px;
      font-size: 14px;
      color: #63697b;
      padding: 0 40px;
      line-height: 30px;
    }
  }
   .energy-h1{
     top:0;
    //  margin-top:102px;
  }
  .energy-h2{
      font-size: 28px ;
      font-family: MicrosoftYaHei;
      color: #193967;
      line-height: 37px;
      top:-43px;
      position: relative;

    }
    .h46{
      height:46px;
    }
}
//ai服务
.aiService-wrap {
  // background: url("../assets/images/aiService-bg.png") no-repeat;
  background-size: 100% 100%;
  padding-bottom: 0px;
  .energy-title {
    color: #fff;
  }
  .energy-nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    line-height: 46px;
    color: rgba(255, 255, 255, 0.9);
    width: 1180px;
    margin: 60px auto 0;
    padding: 0 157px 27px 218px;
    div {
      font-size: 20px;
      border-bottom: 3px solid transparent;
      cursor: pointer;
      transition: all 0.3s ease-in-out;
    }
    div.active {
      border-bottom: 3px solid #44e760;
    }
  }
  .energy-content {
    width: 1180px;
    margin: 0 auto;
    transition: all 0.3s ease-in-out;
    dl {
      display: flex;
    }
    dt {
      padding: 50px 32px 19px 34px;
      width: 374px;
      height: 490px;
      background: rgba(0, 102, 255, 0.44);
      position: relative;
      img {
        position: absolute;
        bottom: 19px;
        right: 32px;
        width: 75px;
      }
    }
    .energy-content_head {
      font-size: 24px;
      color: #fff;
      line-height: 31px;
      margin-bottom: 17px;
    }
    .energy-content_mes {
      font-size: 14px;
      color: rgba(255, 255, 255, 0.9);
      line-height: 24px;
    }
    dd {
      flex: 1;
      margin-left: 0;
      img {
        width: 100%;
      }
    }
  }
}

//aipos
.aiOPS-wrap {
  // background: transparent;
  height:1009px;
  position: relative;
  width:100%;
  overflow: hidden;


}
.aiOPS{

  // overflow: hidden;
  width:1150px;
  margin: 0 auto;
  .aiOPS_bjimg{
    position: absolute;
    left:50%;
    margin-left:-960px;
    bottom:0;
    z-index: 0;
  }
}
.aiOPS-box{
  position: relative;
  z-index: 1;

  .aiOPS_block{
    width:955px;
    height:954px;
    background: url("../assets/img/home_7.png") no-repeat;
    background-size:100% 100%;
    margin:0 auto;
    position: relative;
    top:-100px;
  }
  .po{
    position: absolute;
  }
  .tran{
    transition: all 1s ease-in;
    -webkit-transition:all 1s ease-in;
  }
  .quan{
    position: absolute;
    left:50%;
    top:50%;

  }
  .quan1{
    width:474px;
    height:474px;
    // border-radius: 50%;
    animation:turn 10s linear infinite;
    background: url("../assets/img/home_8.png") no-repeat;
    background-size:100% 100%;
    margin-left:-252px;
    margin-top:-237px;
  }
  .quan2{
    width:580px;
    height:580px;
    // border-radius: 50%;
    background: url("../assets/img/home_9.png") no-repeat;
    animation:turn 20s linear infinite;
    margin-left:-305px;
    margin-top:-290px;
  }
  .a-top{
    top:305px;
    left:406px;

  }
  .a-bom{
     top:624px;
    left:412px;

  }
  .a-leftt{
      top: 472px;
      left: 296px;
    }
  .a-rigtt{
    top: 472px;
    left:593px;
  }
  .a-cen{
    width: 160px;
    text-align: center;
    left:386px;
    top:426px;
      img{
        width:59px;
        height:65px;
      }
      p{
        font-size: 26px;
        line-height: 35px;
        margin-top:31px;
        font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
        font-weight: bold;
        color: #FFFFFF;
      }
  }
  .a-rigy{
    top:409px;
    right:153px;
  }
  .a-lefty{
    top:409px;
    left:165px;
  }
  .a-rigj{
    top:453px;
    right:78px;
  }
  .a-leftj{
    top:453px;
    left:86px;
  }
  .a-nav{
    .a-navbox{
      position: relative;
    }
    .a-imgt{
      top:10px;
      left:0;
    }
    .opt.a-imgt{
      opacity: 0;
      top:70px;
    }
    .xhdh{
       animation: myfirst 1.5s 1s linear infinite alternate;
      -webkit-animation: myfirst 1.5s 1s linear infinite alternate; /* Safari 与 Chrome */
    }
    @keyframes myfirst
        {
            from {top: 10px;}
            to {top: 25px;}
        }
  }
  .a-tl{
    top:155px;
    left:-108px;
     .a-imgt{
      left:33px;
    }
  }
  .a-tr{
    top:155px;
    right:-84px;
    .a-imgt{
      left:42px;
    }
  }
  .a-bl{
    top:534px;
    left:-108px;
    .a-imgt{
      left:54px;
    }
  }
  .a-br{
    top:534px;
    right:-84px;
    .a-imgt{
      left:53px;
    }
  }


  .aiOPS_block2{
      opacity: 1;

  }
  .aiOPS_block3{
    .quan{
        position: absolute;
        left:50%;
        top:50%;
        opacity: 0;
    }
    .a-top{
      top:405px;
      opacity: 0;
    }
    .a-leftt{
      top: 422px;
      left: 388px;
      opacity: 0;
    }
    .a-rigtt{
      top:422px;
      left:482px;
      opacity: 0;
    }
    .a-bom{
      top:524px;
      opacity: 0;
    }

    .a-cen{
      opacity: 0;

    }
  .a-rigy{
    right:253px;
    opacity: 0;
  }
  .a-lefty{
    left:265px;
    opacity: 0;
  }
  .a-rigj{
    right:278px;
    opacity: 0;
  }
  .a-leftj{
    left:286px;
    opacity: 0;
  }
  .a-nav{
       opacity: 0;

    .a-navbox{
      position: relative;
    }



    }
  }

}


@keyframes turn{
      0%{-webkit-transform:rotate(0deg);}
      100%{-webkit-transform:rotate(360deg);}
    }

// 核心产品
.core-box{
  width:1180px;
  margin:0 auto;
  .core-block{
      width:154px;
      height:402px;
      position: relative;
      padding:35px 8px 34px 15px;
      // transition: width 1s;
        transition: all 0.8s ;
        overflow: hidden;
      h3{
        min-height: 42px;
        font-size: 16px;
        font-weight: bold;
        color: #FFFFFF;
        line-height: 21px;
      }
      .core-cont{
        min-height: 48px;
        font-size: 14px;
        color: rgba(255, 255, 255, .85);
        line-height: 24px;
        margin-top:5px;
      }
      .img{
        position: absolute;
        bottom:30px;
        opacity: 0;
        left:50%;
      }
      .core-bom{
        position: absolute;
        right:16px;
        bottom:0;
        span{
          display: block;
          height: 38px;
          font-size: 14px;
          color: #FFFFFF;
          line-height: 19px;
        }
        img{
          width:17px;
          height:17px;
          position: relative;
          top:2px;
          margin-left:10px;
        }
      }
  }
  .core-block0{background: url("../assets/img/home_10.png") no-repeat;background-size:705px 402px; background-position-x: 30%;}
  .core-block1{background: url("../assets/img/home_11.png") no-repeat;background-size:705px 402px; background-position-x: 20%;}
  .core-block3{background: url("../assets/img/home_13.png") no-repeat;background-size:705px 402px; background-position-x: 60%;}
  .core-block4{background: url("../assets/img/home_54.png") no-repeat;background-size:705px 402px; background-position-x: 60%;}
  .core-block2{background: url("../assets/img/home_12.png") no-repeat;background-size:705px 402px; background-position-x: 30%;}
  .core-active{
    width: 550px;
    height: 402px;
    padding:35px 30px 35px 23px;
    .img{
      left:0;
      opacity: 1;
    }
  }
}
.partner-h2{
    line-height: 28px !important;
    top: -40px !important;
}
.partner-box{
  .partner-block{
    width: 224px;
    height: 96px;
    background: #FFFFFF;
    box-shadow: 0px 0px 10px 5px rgba(105, 105, 105, 0.07);
    margin-bottom:13px;
    .partner-imgbox{
       width:100%;
      height:100%;
      overflow: hidden;
    }
    .partner-img{
      width:100%;
      height:100%;
      transition: all 0.5s ease-in;
      -webkit-transition:all 0.5s ease-in;
    }
    .partner-img:hover{
      transform:scale(1.1);
      -ms-transform:scale(1.1); 	/* IE 9 */
      -moz-transform:scale(1.1); 	/* Firefox */
      -webkit-transform:scale(1.1); /* Safari 和 Chrome */
      -o-transform:scale(1.1);


    }
  }
}
.xiaos{
  cursor:pointer;
}
.tran{
    transition: all 1s ease-in;
    -webkit-transition:all 1s ease-in;
  }
</style>

<style lang='less' scoped>
.core-box{
  .core-w{
    min-width: 625px;
    position: absolute;
    opacity: 0;
    left:60%;
    top:120px;
  }
  .core-div{
    text-align: center;
    background: rgba(0,27,82,.6);
    padding-bottom:21px;


    img{
      width:35px;
      height:35px;
    }
    .core-h3{
      font-size: 14px;
      font-family: MicrosoftYaHei;
      color: #FFFFFF;
      line-height: 19px;
      margin:0px 0 10px 0;
      height: 19px;
      min-height: 19px;
    }
    .core-children{
      width:70%;
      height: 29px;
      line-height: 29px;
      background: rgba(23, 47, 133,.52);
      border-radius: 3px;
      margin-bottom:6px;
      margin-left:15%;
      font-size: 12px;
      color: rgba(255, 255, 255,.69);
    }

  }
  .core-div0{
    min-width:198px;
    padding-top:30px;
  }
  .core-div1{
    min-width:198px;
    padding-top:30px;
    margin-top:40px;
  }
  .core-div2{
    min-width:117px;
    padding-top:30px;
  }
  .core-div3{
    min-width:118px;
    padding-top:21px;
  }
  .core-div4{
    min-width:118px;
    padding-top:21px;
  }
  .core-img0{
    position: relative;
    top:-10px;
  }
  .core-img1{
    position: relative;
    left: 135px;
  }
  .core-img3{
    position: relative;
    top:30px;
  }
  .core-img4{
    position: relative;
    top:-33px;
    left:23px;
  }

}
.core-active{
  .core-w{
    min-width: 498px;
    position: absolute;
    opacity: 1;
    left:25px;
  }
}

</style>
