const nav = [{
        title: "工业设备智能化运维产品（AI-OPS）",
        subNavList: [{
                title: "工业机器人产品",
                url: "/product/rebot",
            },
            {
                title: "可视化监拍产品",
                url: "/product/visualization",
            },
            {
                title: "数字孪生产品",
                url: "/product/twins",
            },
            {
                title: "智能安全帽产品",
                url: "/product/safetyHat",
            },
            {
                title: "大数据分析产品",
                url: "/product/bigData",
            },
            {
                title: "AI图像分析产品",
                url: "/product/ai",
            },
        ],
    },
    {
        title: "综合能源服务（INT-ENERGY）",
        subNavList: [{
                title: "能源大数据中心解决方案",
                url: "/energy/bigData",
            },
            {
                title: "多站融合解决方案",
                url: "/energy/fusion",
            },
            {
                title: "弹性电网解决方案",
                url: "/energy/powerGrid",
            },
            {
                title: "中央空调节能解决方案",
                url: "/energy/energySaving",
            },
        ],
    },
    {
        title: "工业设备智能运检服务（AI-Service）",
        subNavList: [{
                title: "智能化水电站检修服务解决方案",
                url: "/service/hydropowerStation",
            },
            {
                title: "变电站设备运维服务解决方案",
                url: "/service/substation",
            },
        ],
    },
]
export default nav