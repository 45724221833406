let env = 'prd'

let href = window.location.href
let http='http'
let httporg = window.location.origin
if ( href.indexOf('localhost') > -1 || href.indexOf('67.176') > -1) {
  env = 'dev'
} else if (href.indexOf('192.168.6.228') > -1) {
  env = 'test'
} else {
  env = 'prd'
}
if (href.indexOf("https") !== -1) {
  http = "https";
} else {
  http = "http";
}


function getParams () {
  //return {
    //appServiceUrl: 
    //'http://t200conference-manager.zhengtoon.com'
 // }
    if (env === 'dev') {
      return {
        // /appServiceUrl: 'http://df.minganmed.com/'    
        appServiceUrl: '/api/xlwebsiteservice/',                         //接口地址
        appServiceUrldown: http+'://192.168.6.228/xlwebsiteservice/',
        appServiceUrlPro:http+'://192.168.6.228/'
      }
    } else if (env === 'test') {
      return {
        appServiceUrl: httporg+'/xlwebsiteservice/',
        appServiceUrldown:  httporg+'/xlwebsiteservice/',
        appServiceUrlPro:http+'://192.168.6.228/'

      }
    } else {
      return {
        appServiceUrl: httporg+'/xlwebsiteservice/',
        appServiceUrldown:  httporg+'/xlwebsiteservice/',
        appServiceUrlPro:httporg+'/'
      }
    }
}

export default getParams()


