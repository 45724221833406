<template>
  <div class="header-wrap" :class='{headcls:headcls}'>
    <div class="header l-between" >
      <div class="logo-wrap" @click="navHandle({ url: '/', title: '小凌泰科' })">
        <div class="logo">
          <img src="../../assets/img/home_49.png" alt="" v-if='headcls'/>
          <!-- <img src="../../assets/images/logo.png" alt="" v-if='!headcls'/> -->
        </div>
        <!-- <div class="logo-line"></div>
        <span>专注工业智能化</span> -->
      </div>
      <div class="nav-wrap">
        <div class="nav-item">
          <span
            class="nav-name"
            :class="{ active: routePath.indexOf('home')>-1 }"
            @click="navHandle({ url: '/', title: '小凌泰科' })"
            >首页</span
          >
        </div>
        <div class="nav-item">
          <span
            class="nav-name"
            :class="{
              active:
                routePath.indexOf('product')>-1 ||
                routePath.indexOf('energy')>-1 ||
                routePath.indexOf('service')>-1,
            }"
            >产品服务</span
          >
          <div class="subnav-wrap">
            <div class="subnav">
              <ul class='l-between'>
                <li
                  v-for="it in navtwoList"

                  :key="it.title"
                  @click="navHandle(it)"
                  :class="[{ active: routePath.indexOf(it.url)>-1 },it.clas]"
                >
                  {{ it.title }}
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="nav-item" v-if='isnews.length>0'>
          <span
            class="nav-name"
            :class="{ active: routePath.indexOf('news')>-1 }"
            @click="navHandle({ url: '/news', title: '新闻动态' })"
            >新闻动态</span
          >
        </div>
        <div class="nav-item">
          <span
            class="nav-name"
            :class="{ active: routePath.indexOf('qualification')>-1 }"
            @click="navHandle({ url: '/qualification', title: '公司资质' })"
            >公司资质</span
          >
        </div>
        <div class="nav-item">
          <span
            class="nav-name"
            :class="{ active: routePath.indexOf('About')>-1 }"
            @click="navHandle({ url: '/About', title: '关于我们' })"
            >关于我们</span
          >
        </div>
        <div class="nav-item">
          <span
            class="nav-name"
            :class="{ active: routePath.indexOf('recruit')>-1 }"
            @click="navHandle({ url: '/recruit', title: '加入我们' })"
            >加入我们</span
          >
        </div>
      </div>
    </div>
    <!-- <div class='twonav'>
        <div class='twonav-box l-between'>
            <div class='twonavlist' v-for='(itm,index) in navtwoList'>

            </div>
        </div>
    </div> -->
    <!-- <div class="rig-d ">
      <div class="box b" @click="gohef">
        <img class="img " src="../../assets/img//rig_1.png" />
        <div class="txt ">报名通道</div>
      </div>
      <div class="leftbox" v-show="isshow">
        <div class="leftboxd">
          <div class="leftx b" @click="isshow = false"></div>
          <img class="img2 " src="../../assets/img//rig_2.png" />
          <img class="img3 " src="../../assets/img//rig_3.png" />
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
import navList from "@/utils/nav.js";
import { selectReceptionTitle } from "@/api/common";

export default {
  name: "Header",
  data() {
    return {
      navList,
      isshow:false,
      routePath: "",
      headcls:true,
      navtwoList:[
        // {title:'工业互联网标识解析系统',url:"/productN/productSatellite",clas:"bj1"},
        {title:"蜂筑工业互联网平台",url:"/productN/productIndex",clas:"bj1"},
        {title:'数据资产确权登记平台',url:"/productN/dataConfirmationRegistration",clas:"bj7"},
        {title:'数据产品可信交易平台',url:"/productN/trustedDataTrading",clas:"bj8"},
        {title:'TMS自动化测试系统',url:"/productN/productRobot",clas:"bj4"},
        {title:'CRM客户关系管理系统',url:"/productN/productHSE",clas:"bj3"},
        // {title:'EMS设备运维管理系统',url:"/productN/productVibration",clas:"bj2"},
        // {title:"商拓邦微信小程序",url:"/productN/productWX",clas:"bj5"},
        // {title:"DCMM贯标培训服务",url:"/productN/productDcmm",clas:"bj6"},




      ],
      isnews:[]
    };
  },
  watch: {
    $route(v) {
      this.routePath = v.path;
    },
  },
  mounted() {
    this.routePath = this.$route.path;
    // document.addEventListener("scroll", () => {
    //   if (window.scrollY > 100) {
    //     this.headcls=true
    //   } else {
    //      this.headcls=false
    //   }
    // });
    this.getList(6)
     this.getList(7)
  },
  methods: {
    gohef(){
      // window.location.href='http://m.xiaoling-tech.com/SignUp'
      this.$router.push({
        path: '/SignUp',
      });
    },
    navHandle(item) {
      document.title = item.title;
      this.$router.push({
        path: item.url,
      });
    },
     getList(id) {
      let data = {
          pageNo:1,
          pageSize:10,
          titleType:id
      };
      selectReceptionTitle(data)
        .then(res => {
          if (res.code == 0) {
            for(let i=0;i<res.data.records.length;i++){
              this.isnews.push(res.data.records[i])
            }

          } else {
          }
        })
        .catch(error => {});
    }
  },
};
</script>

<style lang="less" scoped>
.header-wrap {
  background: rgba(32,22,79,1);
  // background: rgba(8,15,32,.8);
  height: 65px;
  position: fixed;
  z-index: 99;
  width: 100%;
}
.header {
  width: 1180px;
  height: 100%;
  margin: 0 auto;
  display: flex;
}
.logo-wrap {
  display: flex;
  align-items: center;
  height: 100%;
  color: rgba(255, 255, 255, 0.8);
  font-size: 14px;
  cursor: pointer;
  img {
    width: 346px;
    display: block;
  }
  .logo-line {
    width: 1px;
    height: 27px;
    background: #fff;
    margin: 0 10px;
  }
}
.nav-wrap {
  display: flex;
  align-items: center;
  .nav-item {
    margin-right: 82px;
    position: relative;
    height: 100%;
    display: flex;
    align-items: center;
    &:last-child {
      margin-right: 0;
    }
    &:hover {
      .subnav-wrap {
        display: block;
      }
    }
  }
  .nav-name {
    color: rgba(255, 255, 255, 0.8);
    font-size: 16px;
    cursor: pointer;
    &:hover {
      color: #fff;
    }
    &.active {
      color: #4478e7;
    }
  }
}
.subnav-wrap {
  z-index: 1;
  position: absolute;
  width: 100px;
  height: 65px;
  top: 5px;
  left: -20px;
  display: none;
  cursor: pointer;
}
.subnav {
  cursor: auto;
  width: 100vw;
  position: fixed;
  top: 65px;
  left: 0;
  transform: translate(0, 0);
  display: flex;
  justify-content: center;
  // background: rgba(0, 0, 1, 0.9);
  // background: rgba(255, 255, 255, 0.95);
    background:#fff;
  font-size: 16px;
  // color: rgba(255, 255, 255, 0.8);
  color: #25365c;
  padding: 18px 12px 0 22px;
  white-space: nowrap;
  padding-bottom: 20px;
  box-shadow: 0 8px 13px 0 rgba(122, 148, 204, 0.3);
  border-radius: 3px;
  border-top: 1px solid rgba(0, 0, 0, 0.07);

  &::before {
    // content: "";
    width: 0;
    height: 0;
    border-width: 0 10px 10px;
    border-style: solid;
    border-color: transparent transparent rgba(255, 255, 255, 0.9);
    position: absolute;
    top: -10px;
    left: 144px;
  }
  ul {
    margin-right: 55px;
    width:1180px;
    &:last-child {
      margin-right: 0;
    }
  }
  h3 {
    border-bottom: 1px solid #e4e8f2;
    padding-bottom: 10px;
    margin-bottom: 10px;
    color: #fff;
  }
  li {
    // margin-bottom: 10px;
    font-size: 14px;
    line-height: 30px;
    // color: #25345c;
    color: #666666;
    background: url(../../assets/img/footer_2.png) no-repeat left center;
    background-size:13px 13px;
    padding-left:18px;
    cursor: pointer;
    &:hover,
    &.active {
      color: #2875fa;
      // background: url(../../assets/img/footer_3.png) no-repeat left center;
      // background: #f5f8ff;
    }
  }
  .bj1{
    background: url(../../assets/img/head_11.png) no-repeat left center;
    background-position-y: 7px;
    padding-left:20px;
    &:hover,
    &.active {
      color: #2875fa;
      // background: url(../../assets/img/head_1.png) no-repeat left center;
      // background: #f5f8ff;
    }
  }
  .bj2{
    background: url(../../assets/img/head_21.png) no-repeat left center;
    background-position-y: 7px;
    &:hover,
    &.active {
      color: #2875fa;
      // background: url(../../assets/img/head_2.png) no-repeat left center;
      // background: #f5f8ff;
    }
  }
  .bj3{
    background: url(../../assets/img/head_31.png) no-repeat left center;
    background-position-y: 7px;
    padding-left:20px;
    &:hover,
    &.active {
      color: #2875fa;
      // background: url(../../assets/img/head_3.png) no-repeat left center;
      // background: #f5f8ff;
    }
  }
  .bj4{
    background: url(../../assets/img/head_41.png) no-repeat left center;
    background-position-y: 6px;
    padding-left:20px;
    &:hover,
    &.active {
      color: #2875fa;
      // background: url(../../assets/img/head_4.png) no-repeat left center;
      // background: #f5f8ff;
    }
  }
  .bj5{
    background: url(../../assets/img/productWX_1.png) no-repeat left center;
    background-position-y: 8px;
    padding-left:20px;
    &:hover,
    &.active {
      color: #2875fa;
      // background: url(../../assets/img/head_4.png) no-repeat left center;
      // background: #f5f8ff;
    }
  }
  .bj6{
    background: url(../../assets/img/dcmmlogo.png) no-repeat left center;
    background-position-y: 8px;
    padding-left:27px;
    &:hover,
    &.active {
      color: #2875fa;
      // background: url(../../assets/img/head_4.png) no-repeat left center;
      // background: #f5f8ff;
    }
  }
  .bj7{
    background: url(../../assets/img/head_71.png) no-repeat left center;
    background-position-y: 8px;
    padding-left:27px;
    &:hover,
    &.active {
      color: #2875fa;
      // background: url(../../assets/img/head_4.png) no-repeat left center;
      // background: #f5f8ff;
    }
  }
  .bj8{
    background: url(../../assets/img/head_81.png) no-repeat left center;
    background-position-y: 8px;
    padding-left:27px;
    &:hover,
    &.active {
      color: #2875fa;
      // background: url(../../assets/img/head_4.png) no-repeat left center;
      // background: #f5f8ff;
    }
  }
}



.headcls{
  background:#fff;
  box-shadow: 0px 0px 8px rgba(0,0,0,.2);
  .logo-wrap{
    span{
      background-image: linear-gradient(105deg, #4E8CBD 0%, #BC7DBA 100%);
      background-clip:text;
      -webkit-background-clip:text;
      color:transparent;
    }
    .logo-line {
      background: rgba(31, 27, 83, .22);
    }
  }
  .nav-name{
    color: #183967;
    &:hover {
      color: #999;
    }
  }
}
.rig-d {
  position: fixed;
  right: 11px;
  top: 50%;
  z-index: 2000;
  border-radius: 4px;
  text-align: center;
  width: 80px;
  height: 183px;
  background: #FFFFFF;
  box-shadow: 0px 0px 20px 9px rgba(65,127,245,0.13);
  border-radius: 43px;
  padding-top:39px;
  .box {
    width: 100%;
    height: 100%;
  }
  .img {
  }
  .txt {
    width: 19px;
    height: 84px;
    font-size: 16px;
    font-family: MicrosoftYaHei;
    color: #326FFD;
    line-height: 20px;
    padding-top:4px;
    margin: 0 auto;
  }
  .leftbox {
    position: absolute;
    right: 90px;
    top: 0;
    width: 244px;
    height: 433px;
    box-shadow: 0px 0px 14px 8px rgba(0, 0, 0, 0.08);
    border-radius: 4px;
    .leftboxd {
      width: 100%;
      height: 100%;
      position: relative;
    }
    .leftx {
      position: absolute;
      top: 6px;
      right: 12px;
      width: 25px;
      height: 30px;
    }
    .img2 {
      width: 244px;
    }
    .img3 {
      position: absolute;
      top: 174px;
      left: 73px;
      width: 100px;
    }
  }
}
</style>
