import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import privacy from '../views/privacy.vue'
import Product from '../views/product/index.vue'
import Energy from '../views/energy/index.vue'
import Service from '../views/service/index.vue'
import productN from '../views/product2022/index.vue'
import signup from '../views/signup/index.vue'

Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: 'Home',
    component: Home,
    redirect: '/home',
    meta: {
      title: '首页'
    }
  },
  {
    path: '/home',
    name: 'Home',
    component: Home,
    meta: {
      title: '首页'
    }
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: privacy,
    meta: {
      title: '隐私政策'
    }
  },
  {
    path: '/signup',
    name: 'signup',
    component: signup,
    meta: {
      title: '报名通道'
    }
  },
  
  {
    path: '/productN',
    name: 'productN',
    component: productN,
    children: [{
      path: '/productN/productSatellite',
      name: 'productSatellite',
      meta: {
        title: '工业互联网标识解析系统'
      },
      component: () => import( /* webpackChunkName: "product" */ '../views/product2022/productSatellite.vue')
    }, 
    {
      path: '/productN/productRobot',
      name: 'productRobot',
      meta: {
        title: 'CRM客户关系管理系统'
      },
      component: () => import( /* webpackChunkName: "product" */ '../views/product2022/productRobot.vue')
    },
    {
      path: '/productN/productHSE',
      name: 'productHSE',
      meta: {
        title: 'EMS设备运维管理系统'
      },
      component: () => import( /* webpackChunkName: "product" */ '../views/product2022/productHSE.vue')
    }, 
    {
      path: '/productN/productVibration',
      name: 'productVibration',
      meta: {
        title: 'TMS自动化测试系统'
      },
      component: () => import( /* webpackChunkName: "product" */ '../views/product2022/productVibration.vue')
    }, 
    {
      path: '/productN/productWX',
      name: 'productWX',
      meta: {
        title: '商拓邦微信小程序'
      },
      component: () => import( /* webpackChunkName: "product" */ '../views/product2022/productWX.vue')
    }, 
    {
      path: '/productN/productDcmm',
      name: 'productDcmm',
      meta: {
        title: 'DCMM贯标培训服务'
      },
      component: () => import( /* webpackChunkName: "product" */ '../views/product2022/productDcmm.vue')
    },  
    {
      path: '/productN/productIndex',
      name: 'productIndex',
      meta: {
        title: '蜂筑工业互联网平台'
      },
      component: () => import( /* webpackChunkName: "product" */ '../views/product2022/productIndex.vue')
    }, 


    {
      path: '/productN/dataConfirmationRegistration',
      name: 'dataConfirmationRegistration',
      meta: {
        title: '数据资产确权登记平台'
      },
      component: () => import( /* webpackChunkName: "product" */ '../views/product2024/dataConfirmationRegistration.vue')
    }, 
    {
      path: '/productN/trustedDataTrading',
      name: 'trustedDataTrading',
      meta: {
        title: '数据产品可信交易平台'
      },
      component: () => import( /* webpackChunkName: "product" */ '../views/product2024/trustedDataTrading.vue')
    }, 
    
    
  ],
  },
  {
    path: '/product',
    name: 'product',
    component: Product,
    children: [{
      path: '/product/rebot',
      name: 'rebot',
      meta: {
        title: '工业机器人产品'
      },
      component: () => import( /* webpackChunkName: "product" */ '../views/product/rebot.vue')
    }, {
      path: '/product/visualization',
      name: 'visualization',
      meta: {
        title: '可视化监拍产品'
      },
      component: () => import( /* webpackChunkName: "product" */ '../views/product/visualization.vue')
    }, {
      path: '/product/twins',
      name: 'twins',
      meta: {
        title: '数字孪生产品'
      },
      component: () => import( /* webpackChunkName: "product" */ '../views/product/twins.vue')
    }, {
      path: '/product/safetyHat',
      name: 'safetyHat',
      meta: {
        title: '智能安全帽产品'
      },
      component: () => import( /* webpackChunkName: "product" */ '../views/product/safetyHat.vue')
    }, {
      path: '/product/bigData',
      name: 'bigData',
      meta: {
        title: '大数据分析产品'
      },
      component: () => import( /* webpackChunkName: "product" */ '../views/product/bigData.vue')
    }, {
      path: '/product/ai',
      name: 'ai',
      meta: {
        title: 'AI图像分析产品'
      },
      component: () => import( /* webpackChunkName: "product" */ '../views/product/ai.vue')
    }],
  },
  {
    path: '/energy',
    name: 'energy',
    component: Energy,
    children: [{
      path: '/energy/bigData',
      name: 'bigData',
      meta: {
        title: '能源大数据中心解决方案'
      },
      component: () => import( /* webpackChunkName: "energy" */ '../views/energy/bigData.vue')
    }, {
      path: '/energy/fusion',
      name: 'fusion',
      meta: {
        title: '多站融合解决方案'
      },
      component: () => import( /* webpackChunkName: "energy" */ '../views/energy/fusion.vue')
    }, {
      path: '/energy/powerGrid',
      name: 'powerGrid',
      meta: {
        title: '弹性电网解决方案'
      },
      component: () => import( /* webpackChunkName: "energy" */ '../views/energy/powerGrid.vue')
    }, {
      path: '/energy/energySaving',
      name: 'energySaving',
      meta: {
        title: '中央空调节能解决方案'
      },
      component: () => import( /* webpackChunkName: "energy" */ '../views/energy/energySaving.vue')
    }],
  },
  {
    path: '/service',
    name: 'service',
    component: Service,
    children: [{
      path: '/service/hydropowerStation',
      name: 'hydropowerStation',
      meta: {
        title: '智能化水电站检修服务解决方案'
      },
      component: () => import( /* webpackChunkName: "service" */ '../views/service/hydropowerStation.vue')
    }, {
      path: '/service/substation',
      name: 'substation',
      meta: {
        title: '变电站设备运维服务解决方案'
      },
      component: () => import( /* webpackChunkName: "service" */ '../views/service/substation.vue')
    }],
  },
  {
    path: '/news',
    name: 'news',
    meta: {
      title: '新闻动态'
    },
    component: () => import( /* webpackChunkName: "news" */ '../views/product2022/news.vue')
  },
  {
    path: '/projectDetails',
    name: 'projectDetails',
    meta: {
      title: '新闻动态'
    },
    component: () => import( /* webpackChunkName: "projectDetails" */ '../views/product2022/projectDetails.vue')
  },
  {
    path: '/qualification',
    name: 'qualification',
    meta: {
      title: '公司资质'
    },
    component: () => import( /* webpackChunkName: "qualification" */ '../views/qualification.vue')
  },
  {
    path: '/about',
    name: 'About',
    meta: {
      title: '关于我们'
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( /* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/recruit',
    name: 'recruit',
    meta: {
      title: '加入我们'
    },
    component: () => import( /* webpackChunkName: "recruit" */ '../views/recruit.vue')
  }
]
const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(to) {
  return VueRouterPush.call(this, to).catch(err => err)
}
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
// 跳转后返回顶部
router.afterEach((to) => {
 
  window.scrollTo(0, 0);
  TDAPP.onEvent(to.meta.title);
})
export default router
