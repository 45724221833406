import axios from './axios'
import params from '@/utils/params'

const apiURL = {

  //热点
  selectReceptionTitle: params.appServiceUrl + 'title/selectReceptionTitle',

}


//热点
let selectReceptionTitle = (o) => { return axios.post(apiURL.selectReceptionTitle+'?pageNo='+o.pageNo+'&pageSize='+o.pageSize, o) }


export {

  selectReceptionTitle,
  

} 


/**
 *
 * 设置title
 *
 */
export const setTitle = (str) => {
  document.title = str;
  window.setTitle && window.setTitle(str);
}
