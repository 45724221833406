<template>
  <div class="footer-wrap">
    <div class="footer">
      <div class="foot-left" >
        <img class="logo" src="../../assets/img/home_50.png" alt="" />
        <div class="qrcode" v-if="false">
          <img src="../../assets/images/qrcode.png" alt="" />
          <span>关注我们</span>
        </div>
        <div class='left-bom'>
            <div class='left-bom-eml'>服务邮箱：it@xiaoling-tech.com</div>
            <div class='left-bom-assd'>北京地址：北京市昌平区龙域北街3号院1号楼金域国际中心A座14层1411</div>
            <div class='left-bom-assd' style="padding-right:10rpx;">苏州地址：苏州市姑苏区八达街118号(月亮湾地铁站6号口步行220米)苏州新闻大厦1204</div>
        </div>
        <!-- <div class="report">
          <div>Copyright@2017～2021</div>
          <div>北京小凌泰科科技有限公司</div>
          <div>京ICP备2020038370号-1</div>
          <div class="report-icon"><img src="../../assets/images/record.png" alt="" /><span>京公网安备 11011402011394号</span></div>
        </div> -->
      </div>
      <div class="foot-line"></div>
      <div class="foot-right">
        <div class="foot-head">产品服务</div>
        <div class="foot-subnav">
          <ul class='l-between l-wrap'>
            <!-- <h3>{{ item.title }}</h3> -->
            <li
              v-for="it in navListbom"
              :key="it.title"
              @click="navHandle(it)"
              :class="{ active: routePath.indexOf(it.url)>-1 }"
            >
              {{ it.title }}
            </li>
          </ul>
        </div>
        <div class="foot-nav l-between">
          <span
            @click="navHandle({ url: '/', title: '小凌泰科' })"
            :class="{ active: routePath.indexOf('home')>-1 }"
            >首页</span
          >
           <span
           v-if='isnews.length>0'
            @click="navHandle({ url: '/news', title: '公司资质' })"
            :class="{ active: routePath.indexOf('news')>-1 }"
            >新闻动态</span
          >
          <span
            @click="navHandle({ url: '/qualification', title: '公司资质' })"
            :class="{ active: routePath.indexOf('qualification')>-1 }"
            >公司资质</span
          >
          <span
            @click="navHandle({ url: '/About', title: '关于我们' })"
            :class="{ active: routePath.indexOf('About')>-1 }"
            >关于我们</span
          >
          <span
            @click="navHandle({ url: '/recruit', title: '加入我们' })"
            :class="{ active: routePath.indexOf('recruit')>-1 }"
            >加入我们</span
          >
        </div>

      </div>
    </div>
    <div class='bom '>
        <div class='bombox l-start'>

             <div class='txt txt1 b' @click='gohFn'>京ICP备2020038370号-1</div>
             <img src="../../assets/img/footer_2.png" alt="" />
             <div class='txt txt2 b' @click='gohFn2'>京公网安备 11011402011394号</div>
        </div>
    </div>
  </div>
</template>

<script>
import navList from "@/utils/nav.js";
import { selectReceptionTitle } from "@/api/common";

export default {
  data() {
    return {
      navList,
      routePath: "",
      navListbom:[
            // {title:"工业互联网标识解析系统",url:"/productN/productSatellite"},
            {title:"蜂筑工业互联网平台",url:"/productN/productIndex"},
            {title:'数据资产确权登记平台',url:"/productN/dataConfirmationRegistration"},
            {title:'数据产品可信交易平台',url:"/productN/trustedDataTrading"},
            {title:"TMS自动化测试系统",url:"/productN/productRobot"},
             {title:"CRM客户关系管理系统",url:"/productN/productHSE"},
            // {title:"EMS设备运维管理系统",url:"/productN/productVibration"},
            // {title:"商拓邦微信小程序",url:"/productN/productWX"},
            // {title:"DCMM贯标培训服务",url:"/productN/productDcmm"},


      ],
      isnews:[]
    };
  },
  watch: {
    $route(v) {
      this.routePath = v.path;
    },
  },
  mounted() {
    this.routePath = this.$route.path;
    this.getList(6)
    this.getList(7)
  },
  methods: {
    getList(id) {
      let data = {
          pageNo:1,
          pageSize:10,
          titleType:id
      };
      selectReceptionTitle(data)
        .then(res => {
          if (res.code == 0) {
            for(let i=0;i<res.data.records.length;i++){
              this.isnews.push(res.data.records[i])
            }

          } else {
          }
        })
        .catch(error => {});
    },
    gohFn(){
      window.open("https://beian.miit.gov.cn/")
    },
    gohFn2(){
       window.open("http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11011402011394")
    },
    navHandle(item) {
      document.title = item.title;
      this.$router.push({
        path: item.url,
      });
    },
  },
};
</script>

<style lang="less" scoped>
.footer-wrap {
  background: #1f2430;
  height: 249px;
  position: relative;
}
.footer {
  width: 1180px;
  margin: 0 auto;
  display: flex;
}
.foot-line {
  width: 1px;
  border-right: 1px solid rgba(255, 255, 255, .13);
  height: 167px;
  margin-top: 25px;
}
.foot-left {
  // white-space: nowrap;
  min-width:424px;
  margin-top:44px;
  .left-bom{
      margin-top:20px;
      >div{
        font-size: 12px;
        color: #9B9CA1;
        line-height: 16px;
      }
        .left-bom-eml{
            background:url(../../assets/img/footer_3.png) no-repeat;
            background-size:16px 13px;
            padding-left:23px;
            background-position-y: 2px;
        }
        .left-bom-assd{
            margin-top:11px;
            background:url(../../assets/img/footer_4.png) no-repeat;
            background-size:12px 16px;
            padding:0 13px 0 23px;
            background-position-y: 1px;
            line-height: 18px;
        }
  }

  .logo {
    width: 207px;
  }
  .qrcode {
    width: 130px;
    height: 130px;
    background: #fff;
    color: #243159;
    font-size: 12px;
    padding-top: 14px;
    margin-bottom: 30px;
    text-align: center;
    img {
      display: block;
      width: 90px;
      margin: 0 auto 1px;
    }
  }
  .report {
    color: rgba(255, 255, 255, 0.6);
    div {
      margin-bottom: 8px;
    }
  }
  .report-icon{
    display: flex;
    align-items: center;
    img{
      margin-right: 5px;
    }
  }
}
.foot-right {
  margin-left: 25px;
  padding-top: 38px;
  .foot-head {
        height: 19px;
        font-size: 14px;
        font-weight: bold;
        color: #FFFFFF;
        line-height: 19px;
        margin-bottom: 16px;
  }
  .foot-subnav {
    display: flex;
    h3 {
        height: 19px;
        font-size: 14px;
        font-weight: bold;
        color: #FFFFFF;
        line-height: 19px;
        margin-bottom: 16px;
    }
    ul {
      margin-right: 50px;
      width:100%;
      &:last-child {
        margin-right: 0;
      }
    }
    li {
      font-size: 12px;
      color: rgba(255, 255, 255, 0.69);
      margin-bottom: 19px;
      cursor: pointer;
      &:hover {
        color: #2c6efb;
      }
      &.active {
        color: #2c6efb;
      }
    }
  }
  .foot-nav {
    // border-top: 1px solid rgba(151, 151, 151, 0.15);
    // border-bottom: 1px solid rgba(151, 151, 151, 0.15);
    display: flex;
    align-items: center;
    color: rgba(255, 255, 255, 0.7);
    // padding-left: 3px;
    width: 725px;
    height: 35px;
    background: rgba(26, 39, 68, .36);
    padding:0 37px;
    margin-top:12px;
    span {
      font-size: 12px;
      cursor: pointer;
      &:hover {
        color: #fff;
      }
      &.active {
        color: #2c6efb;
      }
    }
  }

}
.bom{
    height: 37px;
    background: #16264A;
    position: absolute;
    left:0;
    bottom:0;
    width:100%;
    line-height: 37px;
    .bombox{
        width:354px;
        margin:0 auto;
       img{
           width: 16px;
            height: 16px;
            position: relative;
            top:10px;
       }
       .txt1{
           margin:0 19px 0 0px;
       }
       .txt:hover{
         color:#2c6efb;
       }
       .txt2{
         margin-left:6px;
       }
       .txt{
           font-size: 12px;
            color: rgba(155, 156, 161, .4);
       }
    }
}
</style>
