<template>
  <div id="app">
    <Header v-if='ishead' />
    <router-view class="routerView" />
    <Footer v-if='ishead' />
  </div>
</template>

<script>
import Header from "./views/header/header";
import Footer from "./views/footer/footer";
export default {
  components: { Header, Footer },
  data() {
    return {
      ishead:true
    };
  },
  mounted() {
    let u = location.href
   
    if(u.indexOf('privacy')!=-1){
      this.ishead = false
    }else{
      if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
      ) {
        window.location = "http://m.xiaoling-tech.com"; //可以换成http地址
      }
    }

    let  k = IEVersion()
    if(k!='-1'){
      let path = '/ie.css'
       var head = document.getElementsByTagName('head')[0];
         var link = document.createElement('link');
         link.href = path;
         link.rel = 'stylesheet';
         link.type = 'text/css';
         head.appendChild(link);
    }
    function IEVersion() {
      var userAgent = navigator.userAgent; //取得浏览器的userAgent字符串  
      var isIE = userAgent.indexOf("compatible") > -1 && userAgent.indexOf("MSIE") > -1; //判断是否IE<11浏览器  
      var isEdge = userAgent.indexOf("Edge") > -1 && !isIE; //判断是否IE的Edge浏览器  
      var isIE11 = userAgent.indexOf('Trident') > -1 && userAgent.indexOf("rv:11.0") > -1;
      if(isIE) {
          var reIE = new RegExp("MSIE (\\d+\\.\\d+);");
          reIE.test(userAgent);
          var fIEVersion = parseFloat(RegExp["$1"]);
          if(fIEVersion == 7) {
              return 7;
          } else if(fIEVersion == 8) {
              return 8;
          } else if(fIEVersion == 9) {
              return 9;
          } else if(fIEVersion == 10) {
              return 10;
          } else {
              return 6;//IE版本<=7
          }   
      } else if(isEdge) {
          return 'edge';//edge
      } else if(isIE11) {
          return 11; //IE11  
      }else{
          return -1;//不是ie浏览器
      }
  }

  
  },
};
</script>

<style lang="less">
#app {
  min-height: 100vh;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  display: flex;
  flex-direction: column;
  position: relative;
  // overflow-y: auto;
}
.routerView {
  padding-top: 65px;
  flex: 1;
}
</style>
