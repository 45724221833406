import axios from './axios'
import params from '@/utils/params'

const apiURL = {

  //热点
  selectReceptionTitle: params.appServiceUrl + 'title/selectReceptionTitle',
  reqSignUpData: params.appServiceUrl + 'question/queryPayQuestion',
  reqSaveData: params.appServiceUrl + 'question/addPayAnswer',
  reqPayMoney: params.appServiceUrl + 'wechatPay/queryWechatPayOrder',

}


//热点
let selectReceptionTitle = (o) => { return axios.post(apiURL.selectReceptionTitle+'?pageNo='+o.pageNo+'&pageSize='+o.pageSize, o) }
let reqSignUpData = (o) => { return axios.post(apiURL.reqSignUpData, o) }
let reqSaveData = (o) => { return axios.post(apiURL.reqSaveData, o) }
let reqPayMoney = (o) => { return axios.get(apiURL.reqPayMoney+o, o) }



export {

  selectReceptionTitle,
  reqSignUpData,
  reqSaveData,
  reqPayMoney
  

} 


/**
 *
 * 设置title
 *
 */
export const setTitle = (str) => {
  document.title = str;
  window.setTitle && window.setTitle(str);
}
