import Axios from 'axios'
import QS from 'querystring'
import Call from './call'
import { Loading } from 'element-ui'
import {MessageBox} from 'element-ui'
import router from '../router'
 let loadingInstance =''
 let that =this
 let flag=false
// TODO baseURL
// Axios.defaults.baseURL = 'http://172.31.33.88:8080'

// TODO 设置超时时间
Axios.defaults.timeout = 30000

// TODO http code 校验
Axios.defaults.validateStatus = function (status) {
  return status
}

// TODO GET 请求 params 序列化
Axios.defaults.paramsSerializer = function (params) {
  return QS.stringify(params)
}

// TODO 设置POST等请求 body 序列化
// Axios.defaults.transformRequest = [function (body) {
//   let data = body || {}
//   if (data instanceof window.FormData) {
//     return data
//   }
//   return QS.stringify(data)
// }]

// request拦截器
Axios.interceptors.request.use(config => {
  // Do something before request is sent
  let userdata =sessionStorage.getItem('userdata')
  if(userdata ){
    userdata = JSON.parse(userdata)
    config.headers.token = userdata.token
  }
  
      // Indicator.open()
    //  loadingInstance.open()
    // loadingInstance=Loading.service({});
  
  
  return config
}, error => {
  // Do something with request error
  //  Indicator.close()
  //  loadingInstance.close();
  return Promise.reject(error)
})

// TODO 设置统一请求拦截
Axios.interceptors.response.use(response => {
  // Indicator.close()
  
  // loadingInstance.close();
  if(response.status=='401'){
    // if(flag) return
    // flag = true
    // MessageBox.alert('您的登录已失效，请重新登录!', '提示', {
    //   confirmButtonText: '确定',
    //   closeOnPressEscape:false,
    //   showClose:false,
    //   callback: action => {
    //     flag = false
    //       router.push({
    //               path: '/login',
    //               query:{}
    //           })
              
    //   }
    //   });
    // return Promise.resolve({data:'您的登录已失效，请重新登录!'})
  }
 
  if (response.status === 200 || response.data.code == 0 || response.data.meta.code == 0) {
    return Promise.resolve(response.data)
  }
  return Promise.reject(response.data)
}, error => {
  // Indicator.close()
  return Promise.reject(error)
})

/**
 * @description 统一 GET 请求
 * @param url
 * @param params --> GET 请求参数（***?name=walid&age=25）
 */
function get (url, params) {
  return new Call((resolve, reject) => {
    Axios.get(url, {params: params})
      .then(response => {
        resolve(response)
      }, response => {
        // console.log('response', response)
        reject(response)
      })
      .catch(error => {
        // console.log('url', error)
        reject(error)
      })
  })
}

/**
 * @description 统一 POST 请求
 * @param url
 * @param body --> POST 请求 data
 */
function post(url, body) {
  return new Call((resolve, reject) => {
    Axios.post(url, body)
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export default {
  get, post
}
